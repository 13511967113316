import { useLocalStorage } from '@vueuse/core';
import { intersection } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, onMounted, watchEffect } from 'vue';

import { useTrackingSnippetQuery } from '@/api/queries/use-tracking-snippet';
import { setupGA, setupGTM } from '@/api/services/google-tag-manager-service';
import { cookieConsentValues, type ICookieConsentSelection } from '@/modules/cookie/types/cookie-types';
import { type IConsentSettings, useTrackingStore } from '@/stores/tracking-store';
import type { IThirdPartySnippetType } from '@/types';

const trackerMap: Partial<Record<IThirdPartySnippetType, (id: string) => void>> = {
  GA: setupGA,
  GTM: setupGTM,
};

export const useConsentStore = defineStore('consent', () => {
  const name = 'consent';
  const cookieValues = useLocalStorage<ICookieConsentSelection | object>(name, {});
  const consentMode = useLocalStorage<IConsentSettings>('consent-mode', {
    functionality_storage: 'granted',
    analytics_storage: 'denied',
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
  });
  const trackingStore = useTrackingStore();

  function setCookies(options: ICookieConsentSelection) {
    cookieValues.value = options;

    const consentValue = options.marketing ? 'granted' : 'denied';
    const consentSettings: IConsentSettings = {
      functionality_storage: consentValue,
      analytics_storage: consentValue,
      ad_storage: consentValue,
      ad_user_data: consentValue,
      ad_personalization: consentValue,
      personalization_storage: consentValue,
      security_storage: consentValue,
    };

    trackingStore.updateConsent(consentSettings);
    consentMode.value = consentSettings;
  }

  function deleteCookies() {
    cookieValues.value = undefined;
  }

  const storedData = computed<ICookieConsentSelection | undefined>(() => {
    if (cookieValues.value) {
      return cookieValues.value as ICookieConsentSelection;
    }

    return undefined;
  });
  const cookieKeys = computed(() => Object.keys(storedData.value || {}).sort());

  const hasSeenCookieConsent = computed(
    () =>
      storedData.value != null &&
      intersection(cookieKeys.value, cookieConsentValues).length === cookieConsentValues.length,
  );

  onMounted(() => {
    // Always initialize GTM because we properly handle the consent and thus can initialize it legally
    // even if the user has not given consent for tracking
    const { data: snippetResponse } = useTrackingSnippetQuery(true);
    const trackingSnippets = computed(() => snippetResponse.value?.data || []);

    watchEffect(() => {
      if (trackingSnippets.value) {
        trackingSnippets.value.forEach((tracking) => {
          const setupFn = trackerMap[tracking.type];

          if (setupFn) {
            setupFn(tracking.typeId);
          }
        });
      }
    });
  });

  return {
    setCookies,
    deleteCookies,
    hasMarketingCookies: computed(() => storedData.value?.marketing === true),
    hasNecessaryCookies: computed(() => storedData.value?.necessary === true),
    hasSeenCookieConsent,
  };
});
